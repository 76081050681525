import { getUsers } from '../../api/dreamAudit';
import {
  RECEIVE_USERS,
  REQUEST_USERS,
  CHANGE_INPUT_USER,
  RESET_USERS
} from '../constants/users';

function receiveUsers(email, data) {
  return {
    type: RECEIVE_USERS,
    email,
    users: data.data.success
  };
};

function requestUsers(email) {
  return {
    type: REQUEST_USERS,
    email
  }
}

export function searchUsers(email) {
  return dispatch => {
    const token = sessionStorage.getItem('token');

    dispatch(requestUsers(email));
    return getUsers(email, token).then(response => {
      dispatch(receiveUsers(email, response.data))
    });
  };
};

export function changeInput(email) {
  return {
    type: CHANGE_INPUT_USER,
    email
  }
}

export function resetUsers() {
  return {
    type: RESET_USERS
  };
};