import { getFolders } from '../../api/dreamAudit';

import {
  RECEIVE_FOLDERS,
  REQUEST_FOLDERS,
  TOGGLE_FOLDER_FILTER
} from '../constants/folders';

function receiveFolders(id, data) {
  return {
    type: RECEIVE_FOLDERS,
    folders: data.data.success,
    id
  };
};

function requestFolders(id) {
  return {
    type: REQUEST_FOLDERS,
    id
  };
};

export function searchFolders(id) {
  return (dispatch, getState) => {
    const token = sessionStorage.getItem('token');

    if (shouldFetchFolders(getState(), id)) {
      dispatch(requestFolders(id));
      return getFolders(id, token).then(response => {
        dispatch(receiveFolders(id, response.data))
      });
    }
  };
};

export function toggleFilter(activate) {
  return {
    type: TOGGLE_FOLDER_FILTER,
    activate
  }
}

function shouldFetchFolders(state, id) {
  if (Number(state.folders.id) === Number(id)) {
    return false;
  } else if (state.folders.isFetching) {
    return false;
  } else {
    return true;
  }
}