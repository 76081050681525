import { 
  RECEIVE_ACTIONS, 
  REQUEST_ACTIONS 
} from '../constants/actions';

const initialState = {
  actions: [],
  isFetching: false
}

export default function actions(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_ACTIONS:
      return {
        ...state,
        actions: action.actions,
        isFetching: false
      };
    case REQUEST_ACTIONS:
      return {
        ...state,
        isFetching: true
      };
    default:
      return state;
  }
}