import {
  REQUEST_SEARCH_LOGS,
  RECEIVE_SEARCH_LOGS,
  RESET_SEARCH_LOGS
} from '../constants/logs';

const initialState = {
  logs: [],
  isFetching: false
}

export default function logs(state = initialState, action) {
  switch (action.type) {
    case REQUEST_SEARCH_LOGS:
      return {
        ...state,
        isFetching: true
      }
    case RECEIVE_SEARCH_LOGS:
      return {
        ...state,
        logs: action.logs,
        isFetching: false
      }
    case RESET_SEARCH_LOGS:
      return {
        logs: [],
        isFetching: false
      }
    default:
      return state
  }
}
