import {
    REQUEST_IMPORT_HISTORY,
    RECEIVE_IMPORT_HISTORY
} from '../constants/importHistory';
import { importHistory } from  '../../api/dreamAudit';

export function getImportHistory(folderId) {
    return (dispatch, getState) => {
        const token = sessionStorage.getItem('token');

        if (shouldFetchImportHistory(getState())) {
        dispatch(requestImportHistory());
        return importHistory(token, folderId).then(response => {
            dispatch(receiveImportHistory(response.data));
        });
        }
    };
};

function shouldFetchImportHistory(state) {
    if (state.importHistory.isFetching) {
        return false;
    }
    return true;
};

function requestImportHistory() {
    return {
        type: REQUEST_IMPORT_HISTORY
    };
};

function receiveImportHistory(data) {
    return {
        type: RECEIVE_IMPORT_HISTORY,
        imports: data.data.success
    };
}
