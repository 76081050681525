import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import ActionItem from './ActionItem';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import PropTypes from 'prop-types';


const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 8,
  },
  category: {
    marginTop: theme.spacing.unit * 4
  },
  firm: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    float: 'right'
  }
});

export class Actions extends Component
{
  state = {
    user: null,
    categories: []
  }
  
  componentDidMount() {
    const { getActions, getFolders, getUsers, getUser, match } = this.props;

    document.title = 'Actions';

    getActions().then(() => {
      const { actions } = this.props;
      const categories = [...new Set(actions.map(action => action.category))];
      this.setState(() => ({
        categories
      }))
    });
    getUser(match.params.userId).then(() => {
      getFolders();
      getUsers();
    })
  }

  renderCategory(category) {
    const { actions, folders, users, user, resetUsers } = this.props;

    return (
      actions.filter(action => action.category === category).map((action, key) => (
        <ActionItem action={action} key={key} folders={folders} users={users} firm={user.firm} resetUsers= {resetUsers} />
      ))
    )
  }

  render() {
    const { classes, user } = this.props;
    const { categories } = this.state;
    
    
    if (!user.firm) {
      return <p>Load</p>
    }

    return (
      <div className={classes.root}>
        <Paper className={classes.firm} elevation={3}>
          <Typography variant="subtitle2" gutterBottom>
            Cabinet: {user.firm.name}
          </Typography>
          <Divider />
          <Typography variant="subtitle2" gutterBottom>
            Client: {user.firstname} {user.lastname}
          </Typography>
        </Paper>

        <Typography variant="h4" gutterBottom component="h2">
          Actions
        </Typography>
        <div id="categories">
          {categories.map((category, key) => (
            <div className={classes.category} key={key}>
              <Typography variant="h5" gutterBottom component="h3">
                {category}
              </Typography>
              {this.renderCategory(category)}
            </div>
          ))}
        </div>
      </div>
    )
  }
}

Actions.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    firm: PropTypes.shape({
      id: PropTypes.number
    })
  }).isRequired,
  actions: PropTypes.array.isRequired,
  folders: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  getActions: PropTypes.func.isRequired,
  getFolders: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  resetUsers: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired
};

Actions.defaultProps = {
  classes: {},
  actions: [],
  folder: [],
  users: []
}

export default withStyles(styles)(Actions);