import {
  RECEIVE_FIRM_FOLDERS,
  REQUEST_FIRM_FOLDERS,
  REQUEST_FIRM_USERS,
  RECEIVE_FIRM_USERS
} from '../constants/firm';

const initialState = {
  folders: {
    items: [],
    isFetching: false
  },
  users: {
    items: [],
    isFetching: false
  }
}

export default function data(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_FIRM_FOLDERS:
      return {
        ...state,
        folders: {
          items: action.folders,
          isFetching: false,
          id: action.id
        }
      };
    case REQUEST_FIRM_FOLDERS:
      return {
        ...state,
        folders: {
          ...state.folders,
          isFetching: true,
          id: action.id
        }
      };
    case RECEIVE_FIRM_USERS:
      return {
        ...state,
        users: {
          items: action.users,
          isFetching: false,
          id: action.id
        },
      };
    case REQUEST_FIRM_USERS:
      return {
        ...state,
        users: {
          ...state.users,
          isFetching: true,
          id: action.id
        }
      };
    default:
      return state;
  }
}