import { connect } from 'react-redux';
import Actions from '../components/Actions';
import { getActions } from '../redux/actions/actions';
import { getFolders, getUsers } from '../redux/actions/firm';
import { getUser } from '../redux/actions/user';
import { resetUsers } from '../redux/actions/users';


const mapStateToProps = state => ({
  actions: state.actions.actions,
  folders: state.firm.folders.items,
  users: state.firm.users.items,
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  getActions: () => dispatch(getActions()),
  getFolders: () => dispatch(getFolders()),
  getUser: (userId) => dispatch(getUser(userId)),
  getUsers: () => dispatch(getUsers()),
  resetUsers: () => dispatch(resetUsers())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Actions);