
import { 
  RECEIVE_GOOGLE_USER,
  REQUEST_GOOGLE_USER,
  DELETE_GOOGLE_USER,
  REQUEST_CURRENT_USER
} from '../constants/google';
import { login, user } from '../../api/dreamAudit';


function receiveGoogleUser(data) {
  return {
    type: RECEIVE_GOOGLE_USER,
    ...data.data.success
  };
};

function requestGoogleUser() {
  return {
    type: REQUEST_GOOGLE_USER
  };
};

export function getGoogleUser(id_token) {
  return (dispatch, getState) => {
    if (shouldGetGoogleUser(getState())) {
      dispatch(requestGoogleUser());
      return login(id_token).then(response => {
        sessionStorage.setItem('token', response.data.data.success.token);
        dispatch(receiveGoogleUser(response.data));
      });
    }
  };
};

function shouldGetGoogleUser(state) {
  if (state.google.isFetching) {
    return false;
  }
  return true;
}

export function disconnect() {
  sessionStorage.removeItem('token');
  return {
    type: DELETE_GOOGLE_USER
  }
}

function requestCurrentUser() {
  return {
    type: REQUEST_CURRENT_USER
  };
};

export function getCurrentUser(token, ownProps) {
  return dispatch => {
    dispatch(requestCurrentUser());
    return user(token).then(response => {
      dispatch(receiveGoogleUser(response.data));
    })
    .catch(() => ownProps.history.push('/'));
  }
}