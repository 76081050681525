import {
    REQUEST_IMPORT_HISTORY,
    RECEIVE_IMPORT_HISTORY
} from '../constants/importHistory';

const initialState = {
  imports: [],
  isFetching: false,
}

export default function importHistory(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_IMPORT_HISTORY:
      return {
        ...state,
        imports: action.imports,
        isFetching: false
      };
    case REQUEST_IMPORT_HISTORY:
      return {
        ...state,
        isFetching: true
      };
    default:
      return state;
  }
}