import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

const styles = theme => ({
  text: {
    margin: 'auto',
    width: 200
  },
  progress: {
    margin: 'auto',
    width: 200,
    paddingLeft: 80
  },
});

export const Callback = (props) => {
  const { classes } = props;

  return (
    <>
      <div className={classes.text}>
        <p>Veuillez patienter</p>
      </div>
      <div className={classes.progress}>
        <CircularProgress />
      </div>
    </>
  )
}

Callback.propTypes = {
  classes: PropTypes.object.isRequired
}

Callback.defaultProps = {
  classes: {},
}

export default withStyles(styles)(Callback);