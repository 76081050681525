import axios from 'axios'

export const instance = axios.create({
  baseURL: process.env.REACT_APP_DREAM_AUDIT_API_URL
});

export const getUsers = (email, token) =>
  instance.post('/support/informations/searchUsers', {
    email
  },
  {
    headers : {
      'Authorization': `Bearer ${token}`
    }
  })


export const getFolders = (userId, token) =>
  instance.get('/support/informations/folders', {
    params: {
      userId
    },
    headers : {
      'Authorization': `Bearer ${token}`
    }
  })

export const login = (id_token) =>
  instance.post('/support/auth/login', {
    id_token
  })

export const user = (token) =>
  instance.post('/support/auth/user', {
    token
  })

export const getUserInformations = (userId, token) =>
  instance.get('/support/informations/user', {
    params: {
      userId
    },
    headers : {
      'Authorization': `Bearer ${token}`
    }
  })

export const searchLogs = (params, token) =>
  instance.get('/support/logs/search', {
    params: {
      ...params,
    },
    headers : {
      'Authorization': `Bearer ${token}`
    }
  })

export const actions = token =>
  instance.get('/support/actions', {
    headers : {
      'Authorization': `Bearer ${token}`
    }
  })

export const folders = (token, firmId) =>
  instance.get('/support/folders', {
    params: {
      firmId
    },
    headers : {
      'Authorization': `Bearer ${token}`
    }
  })

export const users = (token, firmId) =>
  instance.get('/support/users', {
    params: {
      firmId
    },
    headers : {
      'Authorization': `Bearer ${token}`
    }
  })

export const exercises = (token, folderId) =>
  instance.get('/support/exercises', {
    params: {
      folderId
    },
    headers : {
      'Authorization': `Bearer ${token}`
    }
  })

export const folderElements = (token, exerciseId, folderId) =>
  instance.get('/support/folderElements', {
    params: {
      exerciseId,
      folderId
    },
    headers : {
      'Authorization': `Bearer ${token}`
    }
  })

  export const customsheets = (token, exerciseId, folderId) =>
  instance.get('/support/customsheets', {
    params: {
      exerciseId,
      folderId
    },
    headers : {
      'Authorization': `Bearer ${token}`
    }
  })

export const action = (token, endpoint, params) =>
  instance.post(endpoint, {
    ...params,
  }, {
    headers : {
      'Authorization': `Bearer ${token}`
    }
  })

export const payments = (token, userId) =>
  instance.get('/support/informations/payments', {
    params: {
      userId
    },
    headers : {
      'Authorization': `Bearer ${token}`
    }
  })

export const zohoToken = (token, code) =>
  instance.get('/support/zoho/token', {
    params: {
      code
    },
    headers : {
      'Authorization': `Bearer ${token}`
    }
  })

export const createZohoSession = (token, access_token, email) =>
  instance.post('/support/zoho/session', {
    access_token,
    email,
  }, {
    headers : {
      'Authorization': `Bearer ${token}`
    }
  })

export const importHistory = (token, folderId) =>
  instance.get('/support/import-history', {
    params: {
      folderId
    },
    headers : {
      'Authorization': `Bearer ${token}`
    }
  });

const api = {
  getUsers,
  getFolders,
  login,
  user,
  getUserInformations,
  searchLogs,
  actions,
  folders,
  users,
  action,
  exercises,
  folderElements,
  customsheets,
  payments,
  zohoToken,
  importHistory
}

export default api;