import { 
  REQUEST_USER,
  RECEIVE_USER,
  REQUEST_PAYMENTS,
  RECEIVE_PAYMENTS
} from '../constants/user';

const initialState = {
  id: null,
  isFetching: false,
  payments: {
    id: null,
    isFetching: false,
    items: []
  }
}

export default function user(state = initialState, action) {
  switch (action.type) {
    case REQUEST_USER:
      return {
        ...state,
        id: action.id,
        isFetching: true
      };
    case RECEIVE_USER:
      return {
        ...state,
        ...action.user,
        isFetching: false
      };
    case REQUEST_PAYMENTS:
      return {
        ...state,
        payments: {
          ...state.payments,
          isFetching: true,
          id: action.id
        }
      };
    case RECEIVE_PAYMENTS:
      return {
        ...state,
        payments: {
          ...state.payments,
          isFetching: false,
          id: action.id,
          items: action.payments
        }
      }
    default:
      return state;
  }
};