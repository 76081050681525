import { getUserInformations, payments } from '../../api/dreamAudit';
import {
  REQUEST_USER,
  RECEIVE_USER,
  REQUEST_PAYMENTS,
  RECEIVE_PAYMENTS
} from '../constants/user';


export function getUser(id) {
  return (dispatch, getState) => {
    const token = sessionStorage.getItem('token');
    if (shouldFetchUser(getState(), id)) {
      dispatch(requestUser(id));
      return getUserInformations(id, token).then(response => {
        dispatch(receiveUser(id, response.data));
      })
    } else {
      return Promise.resolve()
    }
  }
}

function requestUser(id) {
  return {
    type: REQUEST_USER,
    id
  }
}

function receiveUser(id, data) {
  return {
    type: RECEIVE_USER,
    id,
    user: data.data.success
  };
}

function shouldFetchUser(state, id) {
  if (Number(state.user.id) === Number(id)) {
    return false;
  } else if (state.user.isFetching) {
    return false;
  } else {
    return true;
  }
}

export function getPayments() {
  return (dispatch, getState) => {
    const token = sessionStorage.getItem('token');
    const userId = getState().user.id;
    if (shouldFetchPayments(getState())) {
      dispatch(requestPayments(userId));
      return payments(token, userId).then(response => {
        dispatch(receivePayments(userId, response.data));
      });
    }
  }
}

function requestPayments(id) {
  return {
    type: REQUEST_PAYMENTS,
    id
  }
}

function receivePayments(id, data) {
  const payments = data.data.success ? data.data.success : [];
  return {
    type: RECEIVE_PAYMENTS,
    id,
    payments: payments
  };
}

function shouldFetchPayments(state) {
  if (Number(state.user.payments.id) === Number(state.user.id)) {
    return false;
  } else if (state.user.payments.isFetching) {
    return false;
  } else {
    return true;
  }
}