import { connect } from 'react-redux';
import ImportHistory from '../components/ImportHistory';
import { getImportHistory } from '../redux/actions/importHistory';
import dayjs from 'dayjs';

const mapStateToProps = state => ({
    imports: state.importHistory.imports.map(importHis => {
        importHis.created_at = dayjs(importHis.created_at).format('DD/MM/YYYY HH:mm:ss')
        return importHis;
    })
});

const mapDispatchToProps = dispatch => ({
    getImportHistory: (folderId) => dispatch(getImportHistory(folderId))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ImportHistory);
