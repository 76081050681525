import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import CssBaseline from '@material-ui/core/CssBaseline';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import PropTypes from 'prop-types';


const GOOGLE_BUTTON_ID = 'google-sign-in-button';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  button: {
    marginTop: theme.spacing.unit * 4
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
});

export class Login extends Component
{
  constructor(props) {
    super(props);

    this.onSuccess = this.onSuccess.bind(this);
  }

  componentDidMount() {
    document.title = 'Connexion';

    window.gapi.load('auth2', () => {
      window.gapi.auth2.init({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID
      }).then(res => {
        window.gapi.signin2.render(
          GOOGLE_BUTTON_ID,
          {
            width: 200,
            height: 50,
            theme: 'dark',
            onsuccess: this.onSuccess,
          },
        );
      });
    })
  }

  onSuccess(googleUser) {
    const { loginGoogle } = this.props;
    loginGoogle(googleUser);
    this.setState({ auth: true });

    // history.push('/users');
  }

  render() {
    const { google, classes } = this.props;
    if (google.email) {
      return <Redirect to={{ pathname: '/users' }}/>
    }
    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            DreamAudit Support
          </Typography>
          <div className={classes.button} id={GOOGLE_BUTTON_ID}/>
        </Paper>
      </main>
    )
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  loginGoogle: PropTypes.func.isRequired,
  google: PropTypes.object.isRequired
};

Login.defaultProps = {
  classes: {},
  google: {}
}

export default withStyles(styles)(Login);
