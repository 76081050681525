import { connect } from 'react-redux';
import UserInformations from '../components/UserInformations';
import { getUser } from '../redux/actions/user';

const mapDispatchToProps = dispatch => ({
  getUser: (userId) => dispatch(getUser(userId)),
});

const mapStateToProps = state => ({
  user: state.user
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserInformations);

