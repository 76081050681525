import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import MaterialTable from 'materialui-table';
import Grid from '@material-ui/core/Grid';
import AttributeSearch from './AttributeSearch';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { MuiPickersUtilsProvider, InlineDateTimePicker } from 'material-ui-pickers';
import DayjsUtils from '@date-io/dayjs';
import { Search, Add } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';


const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 8,
  },
  datePicker: {
    width: '100%'
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  searchButton: {
    // marginTop: theme.spacing.unit * 2,
    height: 50,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  paper: {
    width: '100%',
    padding: theme.spacing.unit * 3,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
});


const emptySearch = [{ attribute: '', comparator: '', value: '' }];
const initialColumns = [
  { id: 'code', disablePadding: false, label: 'Status', sortable: true },
  { id: 'method', disablePadding: false, label: 'Action', sortable: true },
  { id: 'error', disablePadding: false, label: 'Erreur', sortable: true },
  { id: 'route', disablePadding: false, label: 'Route', sortable: true },
  { id: 'tag', disablePadding: false, label: 'Source', sortable: true },
  { id: 'time', disablePadding: false, label: 'Date', sortable: true },
  { id: 'folder_element', disablePadding: false, label: 'Feuille de travail', sortable: true },
];

export class History extends Component
{
  initialSate = {
    startDate: new Date().setHours(8, 0, 0, 0),
    endDate: new Date().getTime(),
    search: emptySearch.slice(0),
    onlyErrors: false,
    columns: initialColumns.slice(0) // Clone the array
  };

  state = this.initialSate;
  
  constructor(props) {
    super(props);

    this.handleDateChange     = this.handleDateChange.bind(this);
    this.handleSearchChange   = this.handleSearchChange.bind(this);
    this.handleRemoveSearch   = this.handleRemoveSearch.bind(this);
    this.handleClickAddSearch = this.handleClickAddSearch.bind(this);
    this.handleSubmitSearch   = this.handleSubmitSearch.bind(this);
    this.handleChangeError    = this.handleChangeError.bind(this);
  }
  
  static getDerivedStateFromProps(nextProps, prevState) {
    document.title = 'Logs';
    const { match } = nextProps;

    const type = match.params.folderId ? 'folder': 'user';

    if (prevState.columns.length !== initialColumns.length) {
      prevState.columns.pop();
    }

    if (type === 'folder') {
      prevState.columns.push({ id: 'user', disablePadding: false, label: 'Utilisateur', sortable: true })
    } else if (type === 'user') {
      prevState.columns.push({ id: 'folder', disablePadding: false, label: 'Dossier', sortable: true })
    }
    
    return { columns: prevState.columns }
  }


  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setState({
        ...this.initialSate,
        search: emptySearch.slice(0)
      }, () => {
        this.props.reset();
      });
    }
  }

  handleRemoveSearch(index) {
    var search = this.state.search;
    search.splice(index, 1);
    this.setState({
      search: search
    });
  }

  handleSearchChange(index, data) {
    var search = this.state.search;
    search[index] = data;
    this.setState({
      search: search
    })
  }

  handleDateChange(field, date) {
    this.setState({
      [field]: date
    });
  }

  handleClickAddSearch() {
    this.setState(prevState => ({
      search: [...prevState.search, emptySearch]
    }))
  }

  handleChangeError(event) {
    this.setState({
      onlyErrors: event.target.checked
    })
  }

  handleSubmitSearch(event) {
    event.preventDefault();

    const { searchLogs, match } = this.props;
    const { search, startDate, endDate, onlyErrors } = this.state;
    const { userId, folderId } = match.params; 

    const type = folderId ? 'folder': 'user';

    var query = {
      start: startDate.valueOf(), // convert datetime to millisecons unix timestamp
      end: endDate.valueOf(),
      type: type,
      attributes: [],
      comparators: [],
      values: []
    }

    // merge attributes arrays to a single object to send the request
    query = search.reduce((prev, curr) => {
      if (!curr['attribute'] || !curr['comparator'] || !curr['value']) {
        return prev;
      }
      prev['attributes'].push(curr['attribute']);
      prev['comparators'].push(curr['comparator']);
      prev['values'].push(curr['value']);
      return prev
    }, query);

    if (onlyErrors) {
      query['attributes'].push('code');
      query['comparators'].push('ne');
      query['values'].push(200);
    }

    if (type === 'user') {
      query['attributes'].push('user');
      query['values'].push(userId);
    } else if (type === 'folder') {
      query['attributes'].push('folder');
      query['values'].push(folderId);
    }

    query['comparators'].push('eq');

    searchLogs(query);
  }

  handleClickAction(data) {
    console.log(data)
  }

  render() {
    const { classes, logs, match, t } = this.props;
    const { startDate, endDate, search, onlyErrors, columns } = this.state;
    const title =  match.params.folderId ? 'dossier' : 'utilisateur';
    return (
      <MuiPickersUtilsProvider utils={DayjsUtils}>
        <div className={classes.root}>
          <Typography variant="h4" gutterBottom component="h2">
            Historique {title}
          </Typography>
          <form autoComplete="off" onSubmit={this.handleSubmitSearch} >
            <Paper className={classes.paper} elevation={1}>
              <Grid container spacing={24}>
                <Grid item xs={6}>
                  <InlineDateTimePicker
                    keyboard
                    required
                    className={classes.datePicker}
                    ampm={false}
                    label="Date début"
                    value={startDate}
                    onChange={(date) => this.handleDateChange('startDate', date)}
                    onError={console.log}
                    format="DD/MM/YYYY HH:mm"
                    mask={[
                      /\d/,
                      /\d/,
                      "/",
                      /\d/,
                      /\d/,
                      "/",
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      " ",
                      /\d/,
                      /\d/,
                      ":",
                      /\d/,
                      /\d/,
                    ]}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InlineDateTimePicker
                    keyboard
                    required
                    className={classes.datePicker}
                    ampm={false}
                    label="Date fin"
                    value={endDate}
                    onChange={(date) => this.handleDateChange('endDate', date)}
                    onError={console.log}
                    format="DD/MM/YYYY HH:mm"
                    mask={[
                      /\d/,
                      /\d/,
                      "/",
                      /\d/,
                      /\d/,
                      "/",
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      " ",
                      /\d/,
                      /\d/,
                      ":",
                      /\d/,
                      /\d/,
                    ]}
                  />
                </Grid>
              </Grid>
            {search.map((value, index) => {
              return <AttributeSearch
                key={index} 
                index={index}
                attributes={value}
                removable={search.length !== 1}
                onChange={this.handleSearchChange}
                onRemove={this.handleRemoveSearch}
              />
            })}
            <div>
              <FormControlLabel
                control={
                  <Switch
                    checked={onlyErrors}
                    onChange={this.handleChangeError}
                    color="primary"
                  />
                }
                label="Afficher seulement les erreurs"
              />
            </div>
            <Button variant="contained" className={classes.button} onClick={this.handleClickAddSearch}>
              <Add className={classes.leftIcon} />
              Ajouter
            </Button>
            </Paper>

            <Button type="submit" variant="contained" color="primary" fullWidth className={classes.searchButton}>
              <Search className={classes.leftIcon} />
              Rechercher
            </Button>
          </form>

          <MaterialTable 
            columns={columns}
            data={logs.map(log => ({
              ...log,
              folder_element: t(log.folder_element)
            }))}
            handleClickAction={this.handleClickAction}
          />
        </div>
      </MuiPickersUtilsProvider>
    )
  }
}

History.propTypes = {
  classes: PropTypes.object.isRequired,
  logs: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
  searchLogs: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
}

History.defaultProps = {
  classes: {},
  logs: []
}

export default withStyles(styles)(withTranslation('common')(History));
