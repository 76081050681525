import { folders, users } from '../../api/dreamAudit';

import {
  RECEIVE_FIRM_FOLDERS,
  REQUEST_FIRM_FOLDERS,
  REQUEST_FIRM_USERS,
  RECEIVE_FIRM_USERS
} from '../constants/firm';

function receiveFolders(data, firmId) {
  return {
    type: RECEIVE_FIRM_FOLDERS,
    folders: data.data.success,
    id: firmId
  };
};

function requestFolders(firmId) {
  return {
    type: REQUEST_FIRM_FOLDERS,
    id: firmId
  };
};


function receiveUsers(data, firmId) {
  return {
    type: RECEIVE_FIRM_USERS,
    users: data.data.success,
    id: firmId
  };
};

function requestUsers(firmId) {
  return {
    type: REQUEST_FIRM_USERS,
    id: firmId
  };
};



function shouldFetch(state, data, firmId) {
  if ((state.firm[data].items.length !== 0 || state.firm[data].isFetching) && state.firm[data].id === firmId) {
    return false;
  } else {
    return true;
  }
}

export function getFolders() {
  return (dispatch, getState) => {
    const token = sessionStorage.getItem('token');
    const state = getState();
    const firmId = state.user.firm.id;

    if (shouldFetch(getState(), 'folders', firmId)) {
      dispatch(requestFolders(firmId));
      return folders(token, firmId).then(response => {
        dispatch(receiveFolders(response.data, firmId));
      });
    } else {
      return Promise.resolve()
    }
  };
}


export function getUsers() {
  return (dispatch, getState) => {
    const token = sessionStorage.getItem('token');
    const state = getState();
    const firmId = state.user.firm.id;

    if (shouldFetch(getState(), 'users', firmId)) {
      dispatch(requestUsers(firmId));
      return users(token, firmId).then(response => {
        dispatch(receiveUsers(response.data, firmId))
      });
    } else {
      return Promise.resolve()
    }
  };
}