import { connect } from 'react-redux';
import SearchUsers from '../../components/Dashboard/SearchUsers';
import { searchUsers, changeInput } from '../../redux/actions/users';
import { getUser } from '../../redux/actions/user';


const WAIT_INTERVAL = 1000;
var timer = null;

function handleChange(dispatch, email) {
  clearTimeout(timer);
  dispatch(changeInput(email));
  timer = setTimeout(() => {
    dispatch(searchUsers(email));
  }, WAIT_INTERVAL);
}

const mapStateToProps = (state) => ({
  users: state.users.users,
  search: state.users.search
});

const mapDispatchToProps = (dispatch) => ({
  handleChange: (email) => handleChange(dispatch, email),
  getUser: (userId) => dispatch(getUser(userId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchUsers);
