import { connect } from 'react-redux';
import ScreenSharing from '../components/ScreenSharing';
import { getUser } from '../redux/actions/user';
import { loginAndCreateSession } from '../redux/actions/screensharing';


const mapDispatchToProps = dispatch => ({
  getUser: (userId) => dispatch(getUser(userId)),
  loginAndCreateSession: (code) => dispatch(loginAndCreateSession(code))
});

const mapStateToProps = state => ({
  user: state.user,
  zohoToken: state.screensharing.token,
  customerLink: state.screensharing.session.customer_url,
  supportLink: state.screensharing.session.technician_url
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScreenSharing);
