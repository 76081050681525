import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import blueGrey from '@material-ui/core/colors/blueGrey';
import teal from '@material-ui/core/colors/teal';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';

const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 8,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    height: 230
  },
  paperContent: {
    marginTop: theme.spacing.unit
  },
  paperMembers: {
    color: 'white',
    padding: theme.spacing.unit * 2,
    backgroundColor: blueGrey[500]
  },
  paperCabinet: {
    color: 'white',
    padding: theme.spacing.unit * 2,
    backgroundColor: teal[500]
  },
  folderNumber: {
    marginLeft: 10
  }
});

export class UserInformations extends Component
{
  constructor(props) {
    super(props);

    this.handleClickFolders = this.handleClickFolders.bind(this);
  }

  componentDidMount() {
    const { match, getUser } = this.props;
    
    document.title = 'Information utilisateur';
    getUser(match.params.userId);
  };

  handleClickFolders() {
    const { history, match } = this.props;

    history.push(`/folders/${match.params.userId}`);
  }

  render() {
    const { classes, user } = this.props;
    if (user.isFetching || !user.id) {
      return <p>Loading</p>;
    }

    return (
      <div className={classes.root}>
        <Typography variant="h4" gutterBottom component="h2">
          Informations
        </Typography>
        <Typography variant="h5" gutterBottom component="h3">
          {user.firstname} {user.lastname}
        </Typography>
        <Grid container spacing={40}>
          <Grid item md={6} xs={12}>
            <Paper className={classes.paper}>
              <Typography variant="h6" gutterBottom>
                Client 
                <Chip color="primary" label={`${user.folders_number} dossiers`} className={classes.folderNumber} />
              </Typography>
              <Divider variant="middle" />
              <div className={classes.paperContent}>
              <Typography variant="body1" gutterBottom>
                  Email: {user.email}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Langue: {user.language}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Role: {user.role}
                </Typography>
              </div>
            </Paper>
          </Grid>
          <Grid item md={6} xs={12}>
            <Paper className={classes.paper}>
              <Typography variant="h6" gutterBottom>
                Cabinet
                <Chip color="primary" label={`${user.firm.folders_number} dossiers`} className={classes.folderNumber} />
              </Typography>
              <Divider variant="middle" />
              <div className={classes.paperContent}>
                <Typography variant="body1" gutterBottom>
                  Nom: {user.firm.name}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Pays: {user.firm.country}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Adresse: {user.firm.complete_address}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Téléphone: {user.firm.phone}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Email: {user.firm.email}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Date création: {user.firm.creation}
                </Typography>
              </div>
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={40}>
          {/* <Grid item md={6} xs={12}>
            <Paper className={classes.paperMembers}>
              <Typography gutterBottom variant="h5" color="inherit">
                Membre de l'équipe
              </Typography>
              <Typography gutterBottom variant="body1" color="inherit">
                Consulter les différents membres de l'équipe
              </Typography>
              <Button color="inherit">
                Voir
              </Button>
            </Paper>
          </Grid> */}
          <Grid item md={6} xs={12}>
            <Paper className={classes.paperCabinet}>
              <Typography gutterBottom variant="h5" color="inherit">
                Liste des dossiers
              </Typography>
              <Typography gutterBottom variant="body1" color="inherit">
                Voir les différents dossiers
              </Typography>
              <Button color="inherit" onClick={this.handleClickFolders}>
                Voir
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </div>
    )
  }
}

UserInformations.propTypes = {
  classes: PropTypes.object.isRequired,
  getUser: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

UserInformations.defaultProps = {
  classes: {},
}

export default withStyles(styles)(UserInformations);