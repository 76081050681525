import { RECEIVE_FOLDERS, TOGGLE_FOLDER_FILTER, REQUEST_FOLDERS } from '../constants/folders';

const initialState = {
  folders: [],
  publicFilter: false,
  isFetching: false,
  id: null
}

export default function folders(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_FOLDERS:
      return {
        ...state,
        id: action.id,
        folders: action.folders,
        isFetching: false
      };
    case TOGGLE_FOLDER_FILTER:
      return {
        ...state,
        publicFilter: action.activate
      };
    case REQUEST_FOLDERS:
      return {
        ...state,
        isFetching: true,
        id: action.id
      };
    default:
      return state;
  }
}