import { connect } from 'react-redux';
import Payments from '../components/Payments';
import { getPayments, getUser } from '../redux/actions/user';
import dayjs from 'dayjs';

const mapDispatchToProps = dispatch => ({
  getUser: (userId) => dispatch(getUser(userId)),
  getPayments: () => dispatch(getPayments())
});

const mapStateToProps = state => ({
  payments: state.user.payments.items.sort((a, b) => dayjs(b.expires_at) - dayjs(a.expires_at)),
  isFetching: state.user.payments.isFetching
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Payments);
