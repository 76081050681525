import React, { Component } from 'react';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import { ViewQuilt, Forum } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router";
import PropTypes from 'prop-types';

const drawerWidth = 240;

const menuItems = [
  {
    name: 'Accueil',
    icon: ViewQuilt
  },
  {
    name: 'FAQ',
    icon: Forum
  }
];

const styles = theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar
});

export class Dashboard extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <div className={classes.toolbar} />
          <List>
            {menuItems.map(({ name, icon }, index) => {
              const Icon = icon
              return (
                <ListItem button key={name}>
                  <ListItemIcon><Icon /></ListItemIcon>
                  <ListItemText primary={name} />
                </ListItem>
              )
            })}
          </List>
        </Drawer>
    )
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

Dashboard.defaultProps = {
  classes: {},
}

export default withRouter(withStyles(styles)(Dashboard));