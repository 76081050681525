import { 
  RECEIVE_USERS,
  CHANGE_INPUT_USER,
  REQUEST_USERS,
  RESET_USERS
} from '../constants/users';

const initialState = {
  users: [],
  search: '',
  isFetching: false
}

export default function users(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_USERS:
      return {
        ...state,
        users: action.users,
        search: action.email,
        isFetching: false
      };
    case REQUEST_USERS:
      return {
        ...state,
        search: action.email,
        isFetching: true
      };
    case CHANGE_INPUT_USER:
      return {
        ...state,
        search: action.email
      };
    case RESET_USERS:
      return {
        ...state,
        users: [],
        search: ""
      };
    default:
      return state;
  }
};