import React from 'react';
import { connect } from 'react-redux';
import FolderList from '../components/FolderList';
import { searchFolders, toggleFilter } from '../redux/actions/folders';
import { getUser } from '../redux/actions/user';
import { Public } from '@material-ui/icons';

const filterFolders = (publicFilter, folders, userId) => {
  if (publicFilter) {
    return folders.map(folder => {
      if (folder.user_id !== userId) {
        folder.public = <Public />;
      } else {
        // We must define an empty string to enable sorting
        folder.public = '';
      }
      return folder;
    });
  } else {
    return folders.filter(folder => folder.user_id === userId);
  }
}

const mapStateToProps = state => ({
  folders: filterFolders(
    state.folders.publicFilter,
    state.folders.folders,
    state.user.id
  ),
  publicFolder: state.folders.publicFilter,
  isFetching: state.folders.isFetching
});

const mapDispatchToProps = dispatch => ({
  searchFolders: (userId) => dispatch(searchFolders(userId)),
  toggleFilter: (activate) => dispatch(toggleFilter(activate)),
  getUser: (userId) => dispatch(getUser(userId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FolderList);
