import { combineReducers } from 'redux';
import users from './users';
import folders from './folders';
import google from './google';
import user from './user';
import logs from './logs';
import actions from './actions';
import firm from './firm';
import screensharing from './screensharing';
import importHistory from './importHistory';


export default combineReducers({
  users,
  folders,
  google,
  user,
  logs,
  actions,
  firm,
  screensharing,
  importHistory
})
