import React, { Component } from 'react';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { Build, Info, ViewQuilt, History, Payment, ScreenShare } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router";
import PropTypes from 'prop-types';


const drawerWidth = 240;

const menuItems = [
  {
    name: 'Informations',
    icon: Info,
    url: '/informations/'
  },
  {
    name: 'Actions',
    icon: Build,
    url: '/actions/'
  },
  {
    name: 'Historique',
    icon: History,
    url: '/history/'
  },
  {
    name: 'Paiements',
    icon: Payment,
    url: '/payments/'
  },
  {
    name: 'Assistance',
    icon: ScreenShare,
    url: '/screen-sharing/'
  }
];

const styles = theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar
});

export class User extends Component {
  constructor(props) {
    super(props);

    this.handleClickDashboard = this.handleClickDashboard.bind(this);
    this.handleClickItem = this.handleClickItem.bind(this);
  }

  handleClickDashboard() {
    const { history } = this.props;
    history.push('/users');
  }

  handleClickItem(url) {
    const { history, match } = this.props;
    history.push(url + match.params.userId)
  }

  render() {
    const { classes } = this.props;
    return (
      <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <div className={classes.toolbar} />
          <List>
            <ListItem button onClick={this.handleClickDashboard}>
                <ListItemIcon><ViewQuilt /></ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItem>
            <Divider />
            {menuItems.map(({ name, icon, url }, index) => {
              const Icon = icon
              return (
                <ListItem button key={name} onClick={() => this.handleClickItem(url)}>
                  <ListItemIcon><Icon /></ListItemIcon>
                  <ListItemText primary={name} />
                </ListItem>
              )
            })}
          </List>
        </Drawer>
    )
  }
}

User.propTypes = {
  classes: PropTypes.object.isRequired
};

User.defaultProps = {
  classes: {},
}

export default withStyles(styles)(withRouter(User));