import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';


const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 8,
  },
  fab: {
    marginTop: theme.spacing.unit * 3
  }
});

const comparators = [
  { key: 'eq', name: 'Egal'},
  { key: 'ne', name: 'Différent'}
];

const attributeOptions = [
  { key: 'tag', name: 'Source' },
  { key: 'folder', name: 'Dossier' },
  { key: 'method', name: 'Action' },
  { key: 'route', name: 'Route' },
  { key: 'user', name: 'Utilisateur' }
];

export class AttributeSearch extends Component
{
  // constructor(props) {
  //   super(props);

  //   this.renderAttributeOptions = this.renderAttributeOptions.bind(this);
  // }
  state = { 
    attribute: '',
    comparator: '',
    value: ''
  }

  renderOptions(elements) {
    return elements.map((element, index) => {
      return <MenuItem key={index} value={element.key}>{element.name}</MenuItem>
    })
  }

  handleChange(field, value) {
    this.setState({
      [field]: value
    }, () =>{
      this.props.onChange(this.props.index, this.state);
    });
  }

  render() {
    const { classes, onRemove, index, removable } = this.props;

    return (
      <Grid container spacing={40}>
        <Grid item xs={4}>
          <TextField
            select
            fullWidth
            label="Champ"
            value={this.props.attributes.attribute}
            onChange={(event) => this.handleChange('attribute', event.target.value)}
            margin="normal"
          >
            {this.renderOptions(attributeOptions)}
          </TextField>
        </Grid>
        <Grid item xs={3}>
            <TextField
              select
              fullWidth
              label="Est"
              value={this.props.attributes.comparator}
              onChange={(event) => this.handleChange('comparator', event.target.value)}
              margin="normal"
            >
              {this.renderOptions(comparators)}
            </TextField>
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            id="standard-name"
            label="Valeur"
            className={classes.textField}
            value={this.props.attributes.value}
            onChange={(event) => this.handleChange('value', event.target.value)}
            margin="normal"
          />
        </Grid>
        <Grid item xs={1}>
          {removable &&
            <Fab size="medium" color="secondary" aria-label="Add" className={classes.fab} onClick={() => onRemove(index)}>
              <DeleteIcon />
            </Fab>
          }
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(AttributeSearch);