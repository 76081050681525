import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import MaterialTable from 'materialui-table';


const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 8,
  },
  rootLoading: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex'
  },
  progress: {
    margin: 'auto',
    marginTop: theme.spacing.unit * 6
  }
});

const columns = [
  { id: 'dream_folder_name', disablePadding: false, label: 'Dossier', sortable: true },
  { id: 'plan_name', disablePadding: false, label: 'Nom', sortable: true },
  { id: 'status', disablePadding: false, label: 'Statut', sortable: true },
  { id: 'subscription_id', disablePadding: false, label: 'Id abonnement', sortable: true },
  { id: 'amount', numeric: true, disablePadding: false, label: 'Montant', sortable: true },
  { id: 'currency_symbol', disablePadding: false, label: 'Devise', sortable: true },
  { id: 'created_at', disablePadding: false, label: 'Création', sortable: true  },
  { id: 'expires_at', disablePadding: false, label: 'Expiration', sortable: true },
  { id: 'last_billing_at', disablePadding: false, label: 'Dernier paiement', sortable: true },
  { id: 'next_billing_at', disablePadding: false, label: 'Prochain paiement', sortable: true },
];

export function Payments(props) {
  const { classes, getPayments, getUser, payments, match, isFetching } = props;

  document.title = 'Paiements';

  useEffect(() => {
    getUser(match.params.userId).then(() => {
      getPayments();
    })
  }, [])

  if (isFetching) {
    return (
      <div className={classes.rootLoading}>
        <Typography variant="h4" gutterBottom component="h2">
          Paiements
        </Typography>
        <CircularProgress size={80} className={classes.progress} />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Typography variant="h4" gutterBottom component="h2">
        Paiements
      </Typography>
      <MaterialTable 
        columns={columns}
        data={payments}
      />
    </div>
  )
}

Payments.propTypes = {
  classes: PropTypes.object.isRequired,
  getPayments: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  payments: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

Payments.defaultProps = {
  classes: {},
  payments: [],
  isFetching: false
}

export default withStyles(styles)(Payments);