import { zohoToken, createZohoSession } from '../../api/dreamAudit';

import {
  REQUEST_ZOHO_TOKEN,
  RECEIVE_ZOHO_TOKEN,
  REQUEST_ZOHO_SESSION,
  RECEIVE_ZOHO_SESSION
} from '../constants/screensharing';

function receiveZohoToken(id, data) {
  return {
    type: RECEIVE_ZOHO_TOKEN,
    token: data.data.success.access_token,
    id
  };
};

function requestZohoToken(id) {
  return {
    type: REQUEST_ZOHO_TOKEN,
    id
  };
};

export function getZohoToken(code) {
  return (dispatch, getState) => {
    const token = sessionStorage.getItem('token');
    const state = getState();
    if (shouldFetchToken(state)) {
      dispatch(requestZohoToken(state.user.id));
      return zohoToken(token, code).then(response => {
        dispatch(receiveZohoToken(state.user.id, response.data))
      });
    } else {
      return Promise.resolve();
    }
  };
};

function shouldFetchToken(state) {
  if (Number(state.screensharing.id) === Number(state.user.id)) {
    return false;
  } else if (state.screensharing.token.isFetching) {
    return false;
  } else {
    return true;
  }
}

function shouldFetchSession(state) {
  if (state.screensharing.session.email === state.user.email) {
    return false;
  } else if (state.screensharing.session.isFetching) {
    return false;
  } else {
    return true;
  }
}


export function createSession() {
  return (dispatch, getState) => {
    const token = sessionStorage.getItem('token');
    const state = getState();
    if (shouldFetchSession(state)) {
      dispatch(requestSession());
      return createZohoSession(token, state.screensharing.token.value, state.user.email).then(response => {
        dispatch(receiveSession(response.data))
      });
    } else {
      return Promise.resolve();
    }
  };
};

function receiveSession(data) {
  return {
    type: RECEIVE_ZOHO_SESSION,
    ...data.data.success.representation
  };
};

function requestSession() {
  return {
    type: REQUEST_ZOHO_SESSION
  };
};

export function loginAndCreateSession(code) {
  return (dispatch, getState) => {
    const token = sessionStorage.getItem('token');
    const state = getState();
    if (shouldFetchToken(state)) {
      dispatch(requestZohoToken(state.user.id));
      return zohoToken(token, code).then(response => {
        dispatch(receiveZohoToken(state.user.id, response.data))
        if (shouldFetchSession(state)) {
          dispatch(requestSession());
          return createZohoSession(token, response.data.data.success.access_token, state.user.email).then(response => {
            dispatch(receiveSession(response.data))
          });
        } else {
          return Promise.resolve();
        }
      });
    } else {
      return Promise.resolve();
    }
  };
}