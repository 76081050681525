import React, { Component } from 'react';
import { action as actionApi, exercises, folderElements, customsheets} from '../api/dreamAudit';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { green, red } from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Select from 'react-select';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import MaterialSelect from './Common/Select';
import NoSsr from '@material-ui/core/NoSsr';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { InlineDatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import DayjsUtils from '@date-io/dayjs';
import dayjs from 'dayjs';
import { withRouter } from 'react-router-dom';
import { resetUsers } from '../redux/actions/users';
import SearchUsers from './Dashboard/SearchUsers';
import users from '../redux/reducers/users';

const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '33.33%',
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  snackbarSuccess: {
    backgroundColor: green[600]
  },
  snackbarError: {
    backgroundColor: red[500]
  },
  iconSnackbar: {
    fontSize: 20,
  },
  iconSnackbarVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

export class ActionItem extends Component
{
  state = {
    expanded: false,
    form: [],
    open: false,
    snackbar: false,
    exercises: [],
    folderElements: [],
    customsheets: [],
    folderId: '',
    exerciseId: '',
    error: '',
    users: []
  }

  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleValidate = this.handleValidate.bind(this);
    this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);
  }

  componentWillMount() {
    const { action, firm } = this.props;

    const form = [];
    action.parameters.map(parameter => {
      const value = parameter.name === 'firmId' ? firm.id : '';
      let data = null;
      switch (parameter.type) {
        case 'Date':
          data = {
            name: parameter.name,
            value: null,
            error: false
          };
          break;
        default:
          data = {
            name: parameter.name,
            value: value,
            error: false
          };
          break;
      }

      form.push(data)
      return null;
    });

    this.setState({
      form
    });
  }

  renderSelectFolder(parameter, index) {
    const { folders } = this.props;
    return (
      <TextField
        label="Dossier"
        value={this.state.form[index].value}
        onChange={(event) => this.handleChange(index, event.target.value)}
        helperText={parameter.description}
        error={this.state.form[index].error}
        select
        fullWidth
      >
        {folders.map((folder, key) => (
          <MenuItem key={key} value={folder.id}>
            {folder.name}
          </MenuItem>
        ))}
      </TextField>
    )
  }

  renderSelectUser(parameter, index) {
    const { users } = this.props;
    return (
      <TextField
        label="Client"
        error={this.state.form[index].error}
        value={this.state.form[index].value}
        onChange={(event) => this.handleChange(index, event.target.value)}
        helperText={parameter.description}
        select
        fullWidth
      >
        {users.map((user, key) => (
          <MenuItem key={key} value={user.id}>
            {user.name}
          </MenuItem> 
        ))}
      </TextField>
    )
  }

  renderSelectFolderElement(parameter, index) {
    const { folders, theme, classes } = this.props;
    const { exercises, folderId, folderElements, exerciseId } = this.state;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
    };

    return (
      <>
        <TextField
          label="Dossier"
          value={folderId}
          onChange={(event) => this.handleGetExercises(event.target.value, index)}
          helperText="Nom du dossier"
          select
          fullWidth
        >
          {folders.map((folder, key) => (
            <MenuItem key={key} value={folder.id}>
              {folder.name}
            </MenuItem> 
          ))}
        </TextField>
        <TextField
          label="Exercice"
          value={exerciseId}
          onChange={(event) => this.handleGetFolderElements(event.target.value, index)}
          helperText="Date de l'exercice"
          disabled={!exercises.length}
          select
          fullWidth
        >
          {exercises.map((exercise, key) => (
            <MenuItem key={key} value={exercise.id}>
              {exercise.date}
            </MenuItem> 
          ))}
        </TextField>
        <NoSsr>
          <Select
            placeholder="Feuille de travail"
            value={this.state.form[index].value}
            onChange={(selected) => this.handleChange(index, selected)}
            isDisabled={!folderElements.length}
            options={folderElements.map(folderElement => ({ value: folderElement.id, label: folderElement.name }))}
            components={MaterialSelect}
            styles={selectStyles}
            classes={classes}
            error={this.state.form[index].error}
          />
        </NoSsr>
      </>
    )
  }

  renderSelectCustomsheet(parameter, index) {
    const { folders, theme, classes } = this.props;
    const { exercises, exerciseId, folderId, customsheets} = this.state;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
    };

    return (
      <>
        <TextField
          label="Dossier"
          value={folderId}
          onChange={(event) => this.handleGetExercises(event.target.value, index)}
          helperText="Nom du dossier"
          select
          fullWidth
        >
          {folders.map((folder, key) => (
            <MenuItem key={key} value={folder.id}>
              {folder.name}
            </MenuItem> 
          ))}
        </TextField>
        <TextField
          label="Exercice"
          value={exerciseId}
          onChange={(event) => this.handleGetCustomsheets(event.target.value, index)}
          helperText="Date de l'exercice"
          disabled={!exercises.length}
          select
          fullWidth
        >
          {exercises.map((exercise, key) => (
            <MenuItem key={key} value={exercise.id}>
              {exercise.date}
            </MenuItem>
          ))}
        </TextField>
        <NoSsr>
          <Select
            placeholder={customsheets.length ? "Feuille peronnalisée":"Pas de Feuille"}
            value={this.state.form[index].value}
            onChange={(selected) => this.handleChange(index, selected)}
            isDisabled={!customsheets.length}
            options={customsheets.map(customsheet => ({ value: customsheet.id, label: customsheet.name.split('_')[0] }))}
            components={MaterialSelect}
            styles={selectStyles}
            classes={classes}
            error={this.state.form[index].error}
          />
        </NoSsr>
      </>
    )
  }


  renderSelectFolderExercise(parameter, index) {
    const { folders } = this.props;
    const { exercises, folderId } = this.state;

    return (
      <>
        <TextField
          label="Dossier"
          value={folderId}
          onChange={(event) => this.handleGetExercises(event.target.value, index)}
          helperText="Nom du dossier"
          select
          fullWidth
        >
          {folders.map((folder, key) => (
            <MenuItem key={key} value={folder.id}>
              {folder.name}
            </MenuItem> 
          ))}
        </TextField>
        <TextField
          label="Exercice"
          value={this.state.form[index].value}
          onChange={(event) => this.handleChange(index, event.target.value)}
          helperText="Date de l'exercice"
          disabled={!exercises.length}
          select
          fullWidth
        >
          {exercises.map((exercise, key) => (
            <MenuItem key={key} value={exercise.id}>
              {exercise.date}
            </MenuItem> 
          ))}
        </TextField>
      </>
    );
  }

  renderDate(parameter, index) {
    return (
      <MuiPickersUtilsProvider utils={DayjsUtils}>
        <InlineDatePicker
          label={parameter.description}
          value={this.state.form[index].value ? dayjs.unix(this.state.form[index].value) : null}
          onChange={(date) => this.handleChange(index, date.unix())}
          format="DD/MM/YYYY"
        />
      </MuiPickersUtilsProvider>
    )
  }

  renderDefault(parameter, index) {
    return (
      <TextField 
        label={parameter.description}
        value={this.state.form[index].value}
        onChange={(event) => this.handleChange(index, event.target.value)}
        fullWidth
      />
    )
  }

  renderInput(parameter, key) {
    switch (parameter.type) {
      case 'Date':
        return this.renderDate(parameter, key);
      case 'Number':
        switch (parameter.name) {
          case 'folderId':
            return this.renderSelectFolder(parameter, key);
          case 'userId':
            return this.renderSelectUser(parameter, key);
          case 'folderElementId':
            return this.renderSelectFolderElement(parameter, key);
          case 'customsheetId':
            return this.renderSelectCustomsheet(parameter, key);
          case 'exerciseId':
            return this.renderSelectFolderExercise(parameter, key);
          default:
            return this.renderDefault(parameter, key);
        }
      default:
        return this.renderDefault(parameter, key);
    }
  }

  handleExpand() {
    this.setState(prevState => ({
      ...prevState,
      expanded: !prevState.expanded
    }));
  }

  handleChange(index, value) {
    const form = this.state.form;
    form[index] = {
      ...form[index],
      value,
      error: false
    };
    this.setState(({
      form
    }));
  }

  handleClick() {
    this.setState(() => ({
      open: true
    }))
  }

  handleClose() {
    this.setState(() => ({
      open: false
    }))
  }

  handleValidate() {
    let { form } = this.state;
    const { action } = this.props;
    const token = sessionStorage.getItem('token');
    let hasEmpty = false;

    form = form.map(value => {
      if (!value.value) {
        hasEmpty = true;
        value.error = true;
      }
      return value
    });

    if (hasEmpty) {
      this.setState({
        form,
        open: false
      })
      return;
    }

    const params = form.reduce((prev, current) => {
      if (typeof(current.value) === 'object') {
        return {
          ...prev,
          [current.name]: current.value.value
        }
      } else {
        return {
          ...prev,
          [current.name]: current.value
        }
      }
    }, []);

    actionApi(token, action.endpoint, params)
      .then(() => {
        const execute = action.execute;
        if (execute === 'Reload'){
          const { history, resetUsers } = this.props;
          resetUsers();
          history.push('/');
        }
        this.setState(() => ({
          open: false,
          snackbar: true,
          error: ''
        }))
      })
      .catch(e => {
        return this.setState(() => ({
          open: false,
          snackbar: true,
          error: e.response.data.error.message
        }))
      });
  }

  handleCloseSnackbar() {
    this.setState(() => ({
      snackbar: false
    }))
  }

  resetForm(index) {
    let { form } = this.state;

    form[index] = {
      ...form[index],
      value: '',
      error: false
    };

    this.setState({
      form
    });
  }

  handleGetExercises(folderId, index) {
    const token = sessionStorage.getItem('token');
    exercises(token, folderId).then(response => {
      const exercisesList = response.data.data.success;

      this.resetForm(index);
      
      this.setState({
        exercises: exercisesList,
        folderId,
        exerciseId: ''
      });
    });
  }

  handleGetFolderElements(exerciseId, index) {
    const { folderId } = this.state;

    const token = sessionStorage.getItem('token');

    folderElements(token, exerciseId, folderId).then(response => {
      const folderElements = response.data.data.success;

      this.resetForm(index);

      this.setState({
        folderElements,
        exerciseId
      })
    });
  }

  handleGetCustomsheets(exerciseId, index) {
    const { folderId } = this.state;

    const token = sessionStorage.getItem('token');

    customsheets(token, exerciseId, folderId).then(response => {
      const customsheets = response.data.data.success;

      this.resetForm(index);

      this.setState({
        customsheets,
        exerciseId
      })
    });
  }

  getMessageSnackbar() {
    const { error } = this.state;
    const { classes, action } = this.props;

    if (error === '') {
      return (
        <span id="message-id" className={classes.message}>
          <CheckCircleIcon className={classNames(classes.iconSnackbar, classes.iconSnackbarVariant)} />
          {action.name} effectué
        </span>
      )
    } else {
      return (
        <span id="message-id" className={classes.message}>
          <CheckCircleIcon className={classNames(classes.iconSnackbar, classes.iconSnackbarVariant)} />
          {error}
        </span>
      )
    }
  }

  snackbarClasses() {
    const { classes } = this.props;
    const { error } = this.state;

    return classNames({
      [classes.snackbarSuccess]: error === '',
      [classes.snackbarError]: error !== ''
    })
  }
  render() {
    const { classes, action } = this.props;
    const { expanded } = this.state;

    return (
      <>
        <Snackbar
          open={this.state.snackbar}
          onClose={this.handleCloseSnackbar}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          autoHideDuration={6000}
        >
        <SnackbarContent
          className={this.snackbarClasses()}
          contentprops={{
            'aria-describedby': 'message-id',
          }}
          message={this.getMessageSnackbar()}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleCloseSnackbar}
            >
              <CloseIcon />
            </IconButton>,
          ]}
          />
        </Snackbar>
        <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Confirmer l'action <b>{action.name}</b> ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Annuler
              </Button>
              <Button id="confirm" onClick={this.handleValidate} color="primary" variant="contained">
                Confirmer
              </Button>
            </DialogActions>
        </Dialog>
        <ExpansionPanel expanded={expanded} onChange={() => this.handleExpand()}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div className={classes.column}>
              <Typography id="name" className={classes.heading}>{action.name}</Typography>
            </div>
            {/* <div className={classes.column}>
              <Typography id="description" className={classes.secondaryHeading}>{action.description}</Typography>
            </div> */}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.details}>
            <Grid container spacing={24} id="input-container">
              {action.parameters.map((parameter, key) => {
                return (parameter.name !== 'firmId' &&
                  <Grid item xs={4} key={key}>
                    {this.renderInput(parameter, key)}
                  </Grid>
                )
              })}
              {action.parameters.filter(parameter => parameter.name !== 'firmId').length === 0 &&
                <Typography variant="body1" color="textSecondary">
                  Aucun paramètre nécessaire
                </Typography>
              }
            </Grid>
          </ExpansionPanelDetails>
          <Divider />
          <ExpansionPanelActions>
            <Button id="apply" size="small" color="primary" variant="contained" onClick={this.handleClick}>
              Appliquer
            </Button>
          </ExpansionPanelActions>
        </ExpansionPanel>
      </>
    )
  }
}

ActionItem.propTypes = {
  classes: PropTypes.object.isRequired,
  firm: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  actions: PropTypes.array.isRequired,
  folders: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
};

ActionItem.defaultProps = {
  classes: {},
  actions: []
}

export default withRouter(withStyles(styles, { withTheme: true })(ActionItem));