import {
  REQUEST_SEARCH_LOGS,
  RECEIVE_SEARCH_LOGS,
  RESET_SEARCH_LOGS
} from '../constants/logs';
import { searchLogs } from  '../../api/dreamAudit';

export function reset() {
  return dispatch => {
    return dispatch({
      type: RESET_SEARCH_LOGS
    });
  };
}

export function search(params) {
  return (dispatch, getState) => {
    const token = sessionStorage.getItem('token');

    if (shouldSearchLogs(getState())) {
      dispatch(requestSearchLogs());
      return searchLogs(params, token).then(response => {
        dispatch(receiveSearchLogs(response.data));
      });
    }
  };
};

function shouldSearchLogs(state) {
  if (state.logs.isFetching) {
    return false;
  }
  return true;
};

function requestSearchLogs() {
  return {
    type: REQUEST_SEARCH_LOGS
  };
};

function receiveSearchLogs(data) {
  return {
    type: RECEIVE_SEARCH_LOGS,
    logs: data.data.success
  };
}