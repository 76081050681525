import { actions } from '../../api/dreamAudit';

import {
  RECEIVE_ACTIONS,
  REQUEST_ACTIONS,
} from '../constants/actions';

function receiveActions(data) {
  return {
    type: RECEIVE_ACTIONS,
    actions: data.data.success
  };
};

function requestActions() {
  return {
    type: REQUEST_ACTIONS
  };
};

function shouldFetchActions(state) {
  if (state.actions.actions.length !== 0 || state.actions.isFetching) {
    return false;
  } else {
    return true;
  }
}

export function getActions() {
  return (dispatch, getState) => {
    const token = sessionStorage.getItem('token');

    if (shouldFetchActions(getState())) {
      dispatch(requestActions());
      return actions(token).then(response => {
        dispatch(receiveActions(response.data));
      });
    } else {
      return Promise.resolve();
    }
  };
}