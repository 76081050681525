import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import FolderList from './containers/FolderListContainer';
import SearchUsers from './containers/Dashboard/SearchUsersContainer';
import UserInformations from './containers/UserInformationsContainer';
import Login from './containers/LoginContainer';
import History from './containers/HistoryContainer';
import Actions from './containers/ActionsContainer';
import Payments from './containers/PaymentsContainer';
import ScreenSharing from './containers/ScreenSharingContainer';
import Callback from './components/Callback';
import ImportHistory from './containers/ImportHistoryContainer';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import 'typeface-roboto';
import LoggedInLayoutRoute from './layout/LoggedInLayout';
import rootReducer from './redux/reducers';
import thunk from 'redux-thunk';
import './i18';

const store = createStore(
  rootReducer,
  // compose(
    applyMiddleware(thunk),
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  // )
);

const routing = (store) => (
  <Provider store={store}>
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route path="/callback" component={Callback} />
        <LoggedInLayoutRoute path="/folders/:userId" component={FolderList} />
        <LoggedInLayoutRoute path="/users" component={SearchUsers} menu="dashboard" />
        <LoggedInLayoutRoute path="/informations/:userId" component={UserInformations} />
        <LoggedInLayoutRoute path="/history/:userId/:folderId?" component={History} />
        <LoggedInLayoutRoute path="/actions/:userId" component={Actions} />
        <LoggedInLayoutRoute path="/payments/:userId" component={Payments} />
        <LoggedInLayoutRoute path="/screen-sharing/:userId" component={ScreenSharing} />
        <LoggedInLayoutRoute path="/import-history/:userId/:folderId" component={ImportHistory} />
      </Switch>
    </Router>
  </Provider>
)

ReactDOM.render(routing(store), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA