import { 
  RECEIVE_ZOHO_TOKEN,
  REQUEST_ZOHO_TOKEN,
  RECEIVE_ZOHO_SESSION,
  REQUEST_ZOHO_SESSION,
  DEFAULT_LINK
} from '../constants/screensharing';

const initialState = {
  id: null,
  token: {
    isFetching: false,
    value: null
  },
  session: {
    isFetching: false,
    customer_url: DEFAULT_LINK,
    technician_url: DEFAULT_LINK,
    email: null
  }
}

export default function screensharing(state = initialState, action) {
  switch (action.type) {
    case REQUEST_ZOHO_TOKEN:
      return {
        ...state,
        id: action.id,
        token: {
          ...state.token,
          isFetching: true
        }
      };
    case RECEIVE_ZOHO_TOKEN:
      return {
        ...state,
        token: {
          ...state.token,
          value: action.token,
          isFetching: false
        }
      };
    case REQUEST_ZOHO_SESSION:
      return {
        ...state,
        session: {
          ...state.session,
          isFetching: true,
          email: action.email
        }
      };
    case RECEIVE_ZOHO_SESSION:
      return {
        ...state,
        session: {
          ...state.session,
          customer_url: action.customer_url,
          technician_url: action.technician_url,
          isFetching: false
        }
      };
    default:
      return state;
  }
};