import React from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import History from '../components/History';
import { search, reset } from '../redux/actions/logs';
import { Error } from '@material-ui/icons';


const mapStateToProps = state => ({
  logs: state.logs.logs.map(log => {
    log.time = dayjs(log.time).format('DD/MM/YYYY HH:mm:ss');
    if (log.code !== '200') {
      log.code = <span style={{marginLeft: -28}}><Error color="error"/><span style={{verticalAlign: 'super'}}> {log.code}</span></span>
    }
    return log;
  })
});

const mapDispatchToProps = dispatch => ({
  searchLogs: (params) => dispatch(search(params)),
  reset: () => dispatch(reset())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(History);
