import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';
import green from '@material-ui/core/colors/green';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import PropTypes from 'prop-types';


const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 8,
  },
  paper: {
    padding: theme.spacing.unit * 2
  },
  start: {
    height: 150
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  divider: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit
  }
});

export class ScreenSharing extends Component
{
  state = {
    open: false,
    popup: window,
    codeCheck: 0,
    loading: false,
    success: false
  }

  constructor(props) {
    super(props);

    this.handleClickStart = this.handleClickStart.bind(this);
    this.handleClickConfirm = this.handleClickConfirm.bind(this);
    this.handleClickCancel = this.handleClickCancel.bind(this);
  }

  createZohoAuthUrl() {
    return (
      `
${process.env.REACT_APP_ZOHO_SESSION_BASE_URL}
?client_id=${process.env.REACT_APP_ZOHO_CLIENT_ID}
&scope=${process.env.REACT_APP_ZOHO_SESSION_SCOPE}
&redirect_uri=${process.env.REACT_APP_ZOHO_REDIRECT_URI}
&response_type=code
      `
    )
  }

  componentDidMount() {
    const { getUser, match } = this.props;

    document.title = 'Assistance';
    getUser(match.params.userId);
  }

  handleClickStart() {
    this.setState({
      open: true
    })
  }

  handleClickCopy(target) {
    const { customerLink, supportLink } = this.props;
    navigator.permissions.query({name: 'clipboard-write'}).then(result => {
      if (result.state === 'granted' || result.state === 'prompt') {
        if (target === 'customer') {
          navigator.clipboard.writeText(customerLink);
        } else if (target === 'support') {
          navigator.clipboard.writeText(supportLink);
        }
      }
    });
  }

  handleClickCancel() {
    this.setState({
      open: false
    });
  }

  handleClickConfirm() {
    this.setState({
      open: false,
      loading: true
    });

    const width = 800;
    const height = 700;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2.5;

    const url = this.createZohoAuthUrl();
    this.setState({
      popup: window.open(
        url,
        'Join.me auth',
        `width=${width},height=${height},left=${left},top=${top}`
      )
    })

    const codeCheck = setInterval(() => {
      try {
        const params = new URL(this.state.popup.location).searchParams;
        const code = params.get('code');
        const error = params.get('error');

        if (error) {
          clearInterval(this.state.codeCheck);
          this.handleError();
        }
        if (!code) {
          return;
        }
        clearInterval(this.state.codeCheck);
        this.handleSuccess(code, params);
      } catch (e) { }
    }, 400);

    this.setState({
      codeCheck: codeCheck
    })
  }

  handleSuccess(code) {
    const { loginAndCreateSession } = this.props;
    const { popup } = this.state;

    loginAndCreateSession(code).then(() => {
      const { supportLink } = this.props;
      this.setState({
        success: true,
        loading: false
      });
      window.open(supportLink);
      popup.close();
    })
  }

  handleError() {
    clearInterval(this.state.codeCheck);
  }

  render() {
    const { classes, customerLink, supportLink } = this.props;
    const { loading, success } = this.state;

    const buttonClassname = classNames({
      [classes.start]: true,
      [classes.buttonSuccess]: success,
    });


    return (
      <div className={classes.root}>
        <Dialog
            open={this.state.open}
            keepMounted
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
          <DialogTitle id="alert-dialog-slide-title">
            Confirmation
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Êtes-vous sûr de vouloir démarrer une session de partage d'écran ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button id="cancel" onClick={this.handleClickCancel}>
              Annuler
            </Button>
            <Button onClick={this.handleClickConfirm} color="primary" variant="contained" id="confirm">
              Démarrer
            </Button>
          </DialogActions>
        </Dialog>
        <Typography variant="h4" gutterBottom component="h2">
          Partage d'écran
        </Typography>
        <Grid container spacing={24}>
          <Grid item xs={3}>
            <div className={classes.wrapper}>
              <Button
                id="start"
                variant="contained"
                color="primary"
                onClick={this.handleClickStart}
                disabled={loading}
                className={buttonClassname}
                fullWidth
              >
                Démarrer la session
              </Button>
              {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
          </Grid>
          <Grid item xs={9}>
            <Paper className={classes.paper} elevation={4}>
              <InputLabel>Lien client</InputLabel>
              <Input
                id="customer-url"
                value={customerLink}
                fullWidth
                disableUnderline
                disabled
                endAdornment={
                  <InputAdornment position="end">
                    <Tooltip disableHoverListener placement="top" title="Copié !" leaveDelay={400}>
                      <Button
                        id="customer-copy"
                        variant="contained"
                        color="primary"
                        onClick={() => this.handleClickCopy('customer')}
                      >
                        Copier
                      </Button>
                    </Tooltip>
                  </InputAdornment>
                }
              />
              <Divider className={classes.divider} />
              <InputLabel>Lien support</InputLabel>
              <Input
                id="support-url"
                value={supportLink}
                fullWidth
                disableUnderline
                disabled
                endAdornment={
                  <InputAdornment position="end">
                    <Tooltip disableHoverListener title="Copié !" leaveDelay={400}>
                      <Button
                        id="support-copy"
                        variant="contained"
                        color="primary"
                        onClick={() => this.handleClickCopy('support')}
                      >
                        Copier
                      </Button>
                    </Tooltip>
                  </InputAdornment>
                }
              />
            </Paper>
          </Grid>          
        </Grid>
      </div>
    )
  }
}

ScreenSharing.propTypes = {
  classes: PropTypes.object.isRequired,
  customerLink: PropTypes.string.isRequired,
  supportLink: PropTypes.string.isRequired,
  loginAndCreateSession: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  getUser: PropTypes.func.isRequired
}

ScreenSharing.defaultProps = {
  classes: {},
}


export default withStyles(styles)(ScreenSharing);