import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { AccountCircle } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { connect } from 'react-redux';
import { disconnect, getCurrentUser } from '../redux/actions/google';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import PropTypes from 'prop-types';
import UserMenu from './Menu/User';
import DashboardMenu from './Menu/Dashboard';
import { withRouter } from 'react-router';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  grow: {
    flexGrow: 1,
  }
});

export class LoggedInLayoutBase extends Component {
  state = {
    anchorEl: null,
    redirect: false
  };

  constructor(props) {
    super(props);
  
    this.handleMenu = this.handleMenu.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.disconnect = this.disconnect.bind(this);
  }

  componentWillMount() {
    const { google, getCurrentUser } = this.props;
    
    if (!google.email) {
      if (sessionStorage.getItem('token')) {
        getCurrentUser(sessionStorage.getItem('token'));
      } else {
        this.setState({
          redirect: true
        });
      }
    }
  }

  handleMenu(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose() {
    // history.push('/login');
    this.setState({ anchorEl: null });
  }

  disconnect() {
    const { disconnect } = this.props;

    window.gapi.load('auth2', () => {
      window.gapi.auth2.init({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID
      }).then(res => {
        res.signOut().then(() => {
          disconnect();
          this.setState({
            redirect: true
          })
        });
      });
    });
  }
  // constructor(props) {
  //   super(props);

  //   if ()
  // }
  render() {
    const { classes, menu, children } = this.props;
    const { anchorEl, redirect } = this.state;
    const open = Boolean(anchorEl);
    
    if (redirect) {
      return <Redirect to={{ pathname: '/' }}/>
    }

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar className={classes.appBar} position="fixed">
          <Toolbar>
            <Typography variant="h6" color="inherit" className={classes.grow}>
              DreamAudit Support
            </Typography>
            <div>
              <IconButton
                id="profile-button"
                aria-owns={open ? 'menu-appbar' : undefined}
                aria-haspopup="true"
                onClick={this.handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={this.handleClose}
              >
                <MenuItem onClick={this.disconnect}>Déconnexion</MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
        {menu === 'dashboard' ? (
          <DashboardMenu />
        ) : (
          <UserMenu />
        )}
        <div className={classes.content}>
          <div className={classes.appBarSpacer} />
          {children}
        </div>
      </div>
    )
  }
};

LoggedInLayoutBase.propTypes = {
  classes: PropTypes.object.isRequired
}

LoggedInLayoutBase.defaultProps = {
  classes: {}
}

const mapStateToProps = state => ({
  google: state.google,
  userId: state.user.id
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getCurrentUser: (token) => dispatch(getCurrentUser(token, ownProps)),
  disconnect: () => dispatch(disconnect())
});

export const LoginLayout = withStyles(styles)(
  withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
  )(LoggedInLayoutBase)));

const LoggedInLayoutRoute = ({component: Component, ...rest}) => {
  return (
    <Route {...rest} render={matchProps => (
      <LoginLayout menu={rest.menu}>
        <Component {...matchProps} />
      </LoginLayout>
    )} />
  )
};

export default LoggedInLayoutRoute;